$paxi-orange: #F47521;
$navy-blue: #00455C;
$primary-grey: #212121; 
$secondary-grey: #515151;
$tertiary-grey: #EAEAEA;
$white: #FFFFFF;

$spacing: 20px;

// variables for mobile breakpoints
$breakpoints: (
    md: 48rem, //768px
    lg: 64rem, //1024px
    xl: 80rem, //1280px
)