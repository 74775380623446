
@import './scss/colors';
@import './scss/mixins';
@import './scss/variables';
@import './scss/colors';

html,
body {
  height: 100%;
}
body {
  margin: 0px;
  padding: 0px;
  font-family: Rubik, "Helvetica Neue", sans-serif;
  background-color: #FCFCFC;
  height: 100%;
  .loading-spinner circle {
    stroke: #F47521 !important;
}
}
